import * as React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import iconResponsability from '../../images/solutions/icon-responsability.svg';
import iconRegret from '../../images/solutions/icon-regret.svg';
import iconReparation from '../../images/solutions/icon-reparation.svg';

const RestorativTools = () => {

    return (
        <Layout>
            <SEO title="Restorativ Tools" />
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div className="page-intro">
                        <h1 className="h3 text-left">Restorativ customizes its tools based on the needs of each restorative justice circle.</h1>
                        <p className='lead text-left'>Our tools are deployed based on Restorativ unique assessment-based path to restoration.</p>
                    </div>
                </div>
            </div>

            <div className="tools-img">
                <div className="tools-img__content">
                    <div className="content-wrapper">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4 d-flex mb-3 ">
                                    <div className="card">
                                        <div className="card-body">
                                            <img className='mx-auto mb-2' src={iconResponsability} alt='' />
                                            <h3 className='text-center'>Responsibility</h3>
                                            <ul className='list-bullet'>
                                                <li>Personal reflection</li>
                                                <li>Development plans</li>
                                                <li>Learning logs</li>
                                                <li>Journaling</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 d-flex mb-3">
                                    <div className="card">
                                        <div className="card-body">
                                            <img className='mx-auto mb-2' src={iconRegret} alt='' />
                                            <h3 className='text-center'>Regret</h3>
                                            <ul className='list-bullet'>
                                                <li>Apology letter writing</li>
                                                <li>Communication skills building</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 d-flex mb-3">
                                    <div className="card">
                                        <div className="card-body">
                                            <img className='mx-auto mb-2' src={iconReparation} alt='' />
                                            <h3 className='text-center'>Reparation</h3>
                                            <ul className='list-bullet'>
                                                <li>Goal setting</li>
                                                <li>Time management</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    )
}

export default RestorativTools
